const initialState = {
    loading: false,
    eventVenues: []
}

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        // Pending
        case 'GET_VENUES_PENDING' :
            return {
                ...state,
                loading: true,
            }

        // Failed
        case 'GET_VENUES_FAILED' :
            return {
                ...state,
                loading: false,
            }

        // Successfull
        case 'GET_VENUES_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
                eventVenues: payload
            }

        default :
            return state;
    }
}