const initialState = {
    loading: false,
    voucherTypes: [],
    createdVouchers: []
}

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        // Pending
        case 'CONNECT_VOUCHER_TO_CARD_PENDING' :
        case 'GET_VOUCHER_TYPES_PENDING' :
        case 'CREATE_VOUCHERS_PENDING' :
        case 'CONNECT_VOUCHERS_PENDING' :
            return {
                ...state,
                loading: true,
            }

        // Failed
        case 'CONNECT_VOUCHER_TO_CARD_FAILED' :
        case 'GET_VOUCHER_TYPES_FAILED' :
        case 'CREATE_VOUCHERS_FAILED' :
        case 'CONNECT_VOUCHERS_FAILED' :
            return {
                ...state,
                loading: false,
                createdVouchers: []
            }
        
        // Success - default
        case 'CONNECT_VOUCHER_TO_CARD_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
            }

        case 'CREATE_VOUCHERS_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
                createdVouchers: payload.data
            }

        case 'CONNECT_VOUCHERS_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
            }

        case 'GET_VOUCHER_TYPES_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
                voucherTypes: payload
            }

        case 'GET_VOUCHER_PDF_PENDING' :
            return {
                ...state,
                loading: true,
            }

        case 'GET_VOUCHER_PDF_FAILED' :
        case 'GET_VOUCHER_PDF_SUCCESSFUL':
            return {
                ...state,
                loading: false,
            }
    
        default :
            return state;
    }
}