const initialState = {
    loading: false,
    festivals: []
}

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        // Pending
        case 'GET_FESTIVALS_PENDING' :
            return {
                ...state,
                loading: true,
            }

        // Failed
        case 'GET_FESTIVALS_FAILED' :
            return {
                ...state,
                loading: false,
            }

        // Successfull
        case 'GET_FESTIVALS_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
                festivals: payload
            }

        default :
            return state;
    }
}