import C from '../../config/Constants';

const initialState = {
    isLoggedIn: false,
    loggedInUser: false,
    userId: false,
    userRoles: [],

    loginMessage: false,

    single: false,
    singleUserRole: false,

    loading: false,

    //list of users, for search table
    items: null,
    itemsLoading: false,
    keyword: '',
    roleId: C.USER.ROLE.ANY,
    skip: 0,
    take: C.LIST.ITEMS_PER_PAGE,
    total: 0
};

export default (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case 'USER_RESET':
            return initialState;

        //USER LIST ACTIONS
        case 'USER_LOADING_GET_LIST':
            return {
                ...state,
                itemsLoading: true
            };
        case 'USER_GET_LIST_SUCCESS':
            return {
                ...state,
                items: payload.results,
                total: payload.total,
                itemsLoading: false
            };

        case 'USER_LOGIN_SUCCESSFUL':
            state = {
                ...state,
                loading: false,
                isLoggedIn: true,
                userId: payload.UserId,
                userRoles: payload.role,
                loginMessage: false,
                loggedInUser: payload
            };
            return state;

        case 'USER_LOGIN_FAILURE':
            state = {
                ...initialState,
                username: '',
                userId: '',
                isLoggedIn: false,
                loading: false
            };
            return state;

        // SINGLE USER LIST ACTIONS
        case 'SINGLE_USER_LOADING_GET':
            return {
                ...state,
                loading: true,
                single: null
            };

        case 'SINGLE_USER_GET_SUCCESS':
            return {
                ...state,
                single: payload,
                loading: false
            };

        case 'SINGLE_USER_GET_FAILURE':

            return {
                ...state,
                single: null,
                loading: false
            };

        // Edit single user
        case 'USER_LOADING_EDIT':
            return {
                ...state,
                loading: true
            };

        case 'USER_EDIT_SUCCESS':
            return {
                ...state,
                loading: false
            };

        case 'USER_EDIT_FAILURE':
            return {
                ...state,
                loading: false
            };

        // Edit user roles
        case 'USER_ROLES_LOADING_EDIT':
            return {
                ...state,
                loading: true
            };

        case 'USER_ROLES_EDIT_SUCCESS':
            return {
                ...state,
                loading: false
            };

        case 'USER_ROLES_EDIT_FAILURE':
            return {
                ...state,
                loading: false
            };

        // Change user pass
        case 'USER_PASS_LOADING_CHANGE':
            return {
                ...state,
                loading: true
            };

        case 'USER_PASS_CHANGE_SUCCESS':
            return {
                ...state,
                loading: false
            };

        case 'USER_PASS_CHANGE_FAILURE':
            return {
                ...state,
                loading: false
            };

        // Add user
        case 'USER_LOADING_CREATE':
            return {
                ...state,
                loading: true
            };

        case 'USER_CREATE_SUCCESS':
            return {
                ...state,
                loading: false
            };

        case 'USER_CREATE_FAILURE':
            return {
                ...state,
                loading: false
            };


        // Member list

        case 'FETCH_USERS_PENDING' :
        case 'USER_LOGIN_PENDING' :
        case 'USER_RESET_PASSWORD_PENDING' :
        case 'SEND_PASSWORD_RESET_LINK_PENDING' :
            return {
                ...state,
                loading: true
            }

        case 'FETCH_USERS_FAILED' :
        case 'USER_RESET_PASSWORD_FAILED' :
        case 'SEND_PASSWORD_RESET_LINK_FAILED' :
            return {
                ...state,
                loading: false,
                skip: 0,
                take: C.LIST.ITEMS_PER_PAGE,
                total: 0,
                items: [],
                ...payload.params
            }

        case 'USER_RESET_PASSWORD_SUCCESSFUL' :
        case 'SEND_PASSWORD_RESET_LINK_SUCCESSFUL' :
            return {
                ...state,
                loading: false
            }

        case 'FETCH_USERS_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
                ...payload.params,
                items: payload.results,
                total: payload.total,
            }

        case 'USER_LOGIN_FAILED' :
            return {
                ...state,
                loading: false,
                loginMessage: payload
            }

        default:
            return state;
    }
};