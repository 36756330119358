import C from "../../config/Constants";

const initialState = {
    loading: false,
    singleMember: false,
    members: [],
    keyword: '',
    roleId: '',
    status: C.MEMBER.STATUS.ANY,
    error: false,
    skip:0,
    take:C.LIST.ITEMS_PER_PAGE,
    total:0
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {

        case "RESET_MEMBER":
            return initialState;

        // Pending
        case 'CREATE_MEMBER_PENDING' :
        case 'FETCH_MEMBER_PENDING' :
        case 'EDIT_MEMBER_PENDING' :
        case 'EDIT_MEMBER_PASS_PENDING' :
        case 'FETCH_MEMBERS_PENDING' :
        case 'REMOVE_RECURRING_PENDING' :
            return {
                ...state,
                loading: true
            }

        case 'CREATE_MEMBER_SUCCESSFUL' :
        case 'FETCH_MEMBER_FAILED' :
        case 'FETCH_MEMBERS_FAILED' :
            return {
                ...state,
                loading: false,
                members:[],
                total:0,
                skip:0,
                ...payload.params,
            }

        
        case 'CREATE_MEMBER_FAILED' :
            return {
                ...state,
                loading: false,
                error: payload,
            }
        
        // FETCH SINGLE
        case 'FETCH_MEMBER_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
                singleMember: payload
            }

        // Fetch multiple
        case 'FETCH_MEMBERS_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
                ...payload.params,
                members: payload.results,
                total:payload.total
            }
        
        // EDIT
        case 'EDIT_MEMBER_SUCCESSFUL' :
        case 'EDIT_MEMBER_FAILED' :
        case 'EDIT_MEMBER_PASS_SUCCESSFUL' :
        case 'EDIT_MEMBER_PASS_FAILED' :
            return {
                ...state,
                loading: false
            }

        // Member list
        case 'CHANGE_MEMBER_KEYWORD' :
            return {
                ...state,
                keyword: payload
            }

        case 'CHANGE_MEMBER_STATUS_FILTER' :
            return {
                ...state,
                status: payload
            }

        case 'CHANGE_MEMBER_TAKE' :
            return {
                ...state,
                take: payload
            }

        case 'CHANGE_MEMBER_SKIP' :
            return {
                ...state,
                skip: payload
            }

        case 'MEMBER_DELETE_FAILURE' :
            return {
                ...state,
                loading: false
            }
            
        case 'MEMBER_DELETE_SUCCESS' :
            return {
                ...state,
                loading: false,
                members: [],
                total: 0
            }

        case 'MEMBER_LOADING_DELETE' :
            return {
                ...state,
                loading: true
            }

        // REMOVE RECURRING
        case 'REMOVE_RECURRING_FAILED' :
        case 'REMOVE_RECURRING_SUCCESSFUL' :
            return {
                ...state,
                loading: false
            }
            
        default:
            return state;
    }
};