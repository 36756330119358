import "babel-polyfill";
import "isomorphic-fetch";
import React, { Component } from "react";
import ReactDOM from "react-dom";
// import axios from "axios";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { persistStore, persistReducer } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import { isLogged } from "./helpers/Helpers";
//TODO : when loggin  will be imlemented ahve to reimplement also this..
//import {pingMe} from './store/actions/user';
import { PersistGate } from "redux-persist/es/integration/react";
import storage from "redux-persist/lib/storage";
import reducers from "./store/reducers";
import asyncComponent from "./AsyncComponent";

/**
 * Async components: Frontend Layout
 */
const FrontLayout = asyncComponent(() =>
    import("./layouts/FrontLayout")
);

const LoginLayout = asyncComponent(() =>
    import("./layouts/LoginLayout")
);

/**
 * Async components: Frontend
 */

var Home = null;
var NotFound = null;

var UserList = null;
var UserEdit = null;
var UserAdd = null;

var MemberAdd = null;
var MemberList = null;
var MemberEdit = null;

var MembershipList = null;
var MembershipActivate = null;
var MembershipTypeAdd = null;
var MembershipPrint = null;

var VoucherAdd = null;
var VoucherConnectToCard = null;

var Purchases = null;
var PurchaseMembercard = null;
var PurchasesList = null;
var PurchaseSingle = null;

var ShadowBookingAdd = null;
var ShadowBookingList = null;
var ShadowBookingEdit = null;
var SchoolBookingAdd = null;
var SchoolBookingList = null;

var ReportsSales = null;
var ReportsProductSales = null;
var ReportsMember = null;
var ReportsFilm = null;
var ReportsVenue = null;
var ReportsDailySales = null;

var Dev = null;

// Login
var Login = null;
var ResetPassword = null;

Home = asyncComponent(() => import("./pages/Home"));
NotFound = asyncComponent(() => import("./pages/NotFound"));
UserList = asyncComponent(() => import("./pages/UserList"));
UserEdit = asyncComponent(() => import("./pages/UserEdit"));
UserAdd = asyncComponent(() => import("./pages/UserAdd"));

MemberAdd = asyncComponent(() => import("./pages/MemberAdd"));
MemberList = asyncComponent(() => import("./pages/MemberList"));
MemberEdit = asyncComponent(() => import("./pages/MemberEdit"));
MembershipList = asyncComponent(() => import("./pages/MembershipList"));
MembershipActivate = asyncComponent(() => import("./pages/MembershipActivate"));
MembershipTypeAdd = asyncComponent(() => import("./pages/MembershipTypeAdd"));
MembershipPrint = asyncComponent(() => import("./pages/MembershipPrint"));

VoucherAdd = asyncComponent(() =>  import("./pages/VoucherAdd"));
VoucherConnectToCard = asyncComponent(() =>  import("./pages/VoucherConnectToCard"));

PurchaseMembercard = asyncComponent(() => import("./pages/PurchaseMembercard"));
Purchases = asyncComponent(() => import("./pages/Purchases"));
PurchasesList = asyncComponent(() => import("./pages/PurchasesList"));
PurchaseSingle = asyncComponent(() => import("./pages/PurchaseSingle"));

ShadowBookingAdd = asyncComponent(() => import('./pages/ShadowBookingAdd'))
ShadowBookingList = asyncComponent(() => import('./pages/ShadowBookingList'))
ShadowBookingEdit = asyncComponent(() => import('./pages/ShadowBookingEdit'))
SchoolBookingAdd = asyncComponent(() => import('./pages/SchoolBookingAdd'))
SchoolBookingList = asyncComponent(() => import('./pages/SchoolBookingList'))

ReportsSales = asyncComponent(() => import("./pages/ReportsSales"));
ReportsProductSales = asyncComponent(() => import("./pages/ReportsProductSales"));
ReportsDailySales = asyncComponent(() => import("./pages/ReportsDailySales"));
ReportsMember = asyncComponent(() => import("./pages/ReportsMember"));
ReportsFilm = asyncComponent(() => import("./pages/ReportsFilm"));
ReportsVenue = asyncComponent(() => import("./pages/ReportsVenue"));

Dev = asyncComponent(() =>
    import("./pages/Dev"),
);

Login = asyncComponent(() =>
    import("./pages/Login"),
);

ResetPassword = asyncComponent(() =>
    import("./pages/ResetPassword"),
);

/**
 * Middleware
 */
const middleware = [
    thunkMiddleware
];

/**
 * Persist
 */
const persist = persistReducer(
    {
        key: "root",
        storage: storage,
        blacklist: ["flash", "routing", "media", "i18n"]
    },
    reducers
);

/**
 * Store configuration
 */
let store;
if (process.env.NODE_ENV === "development") {
    store = createStore(
        persist,
        composeWithDevTools(applyMiddleware(...middleware))
    );
} else {
    store = createStore(persist, applyMiddleware(...middleware));
}

//store.dispatch(pingMe());

const gate = persistStore(store);

/**
 * Get renderer
 */
let initialLoad = true;
let initialRoute = "customer";

/**
 * Configure axios
 */
// axios.interceptors.response.use(
//     response => {
//         return response;
//     },
//     function (error) {
//         store.dispatch({});

//         return Promise.reject(error);
//     }
// );

console.log("version: 1.0.3");

/**
 * With Frontend layout
 */
const withFrontendLayout = (WrappedComponent, frontendTheme, pageId) => {
    return class WithAuthorization extends Component {
        componentDidMount() {
            document.getElementById("application").classList.add("filmfestivalen");
        }

        componentWillMount() {
            const {
                location: { pathname }
            } = this.props;

            if (initialLoad && initialRoute) {
                if (initialRoute !== pathname && pathname === "/") {
                    //window.location.replace("/");
                    initialLoad = false;
                }
            }
        }

        render() {
            let props = this.props;
            if (pageId) {
                props.params.page = pageId;
            }

            if (!isLogged()) {
                return (
                    <LoginLayout {...props} frontendTheme={frontendTheme}>
                        <WrappedComponent {...props} frontendTheme={frontendTheme}/>
                    </LoginLayout>
                );
            }

            return (
                <FrontLayout {...props} frontendTheme={frontendTheme}>
                    <WrappedComponent {...props} frontendTheme={frontendTheme}/>
                </FrontLayout>
            );
        }
    };
};

function PrivateRoute ({component: Component, authed, ...rest}) {
    return (
      <Route
        {...rest}
        render={(props) => isLogged()
          ? <Component {...props} />
          : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
      />
    )
  }

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={gate}>
            <Router>
                <Switch>
                    {/* Login */}
                    <Route path="/login" exact component={withFrontendLayout(Login)} />
                    <Route path="/aterstall-losenord" exact component={withFrontendLayout(ResetPassword)} />

                    <PrivateRoute path="/" exact component={withFrontendLayout(Home)}/>

                    {/* Users */}
                    <PrivateRoute path="/anvandare/sok-anvandare" exact component={withFrontendLayout(UserList)}/>
                    <PrivateRoute path="/anvandare/sok-anvandare/:id" exact component={withFrontendLayout(UserEdit)}/>
                    <PrivateRoute path="/anvandare/lagg-till-anvandare" exact component={withFrontendLayout(UserAdd)}/>

                    {/* Members */}
                    <PrivateRoute path="/medlem/sok-andra-medlem" exact component={withFrontendLayout(MemberList)}/>
                    <PrivateRoute path="/medlem/sok-andra-medlem/:id" exact component={withFrontendLayout(MemberEdit)}/>
                    <PrivateRoute path="/medlem/lagg-till-ny-medlem" exact component={withFrontendLayout(MemberAdd)}/>

                    {/* Membership */}
                    <PrivateRoute path="/medlemskort/sok-medlemskort" exact component={withFrontendLayout(MembershipList)}/>
                    <PrivateRoute path="/medlemskort/aktivera-medlemskort" exact component={withFrontendLayout(MembershipActivate)}/>
                    <PrivateRoute path="/medlemskort/skapa-nya-medlemskort" exact component={withFrontendLayout(MembershipTypeAdd)}/>
                    <PrivateRoute path="/medlemskort/nummerserier-for-tryck" exact component={withFrontendLayout(MembershipPrint)}/>

                    {/* Vouchers */}
                    <PrivateRoute path="/vouchers/skapa-ny-voucher" exact component={withFrontendLayout(VoucherAdd)}/>
                    <PrivateRoute path="/vouchers/koppla-voucher-till-kort" exact component={withFrontendLayout(VoucherConnectToCard)}/>

                    {/* Purchase */}
                    <PrivateRoute path="/kop/sok-kop" exact component={withFrontendLayout(PurchasesList)} />
                    <PrivateRoute path="/kop/kop-medlemskort" exact component={withFrontendLayout(PurchaseMembercard)} />
                    <PrivateRoute path="/kop/kop-biljetter" exact component={withFrontendLayout(Purchases)} />
                    <PrivateRoute path="/kop/:id" exact component={withFrontendLayout(PurchaseSingle)} />

                    {/* Shadow/School Booking */}
                    <PrivateRoute path="/skuggbokning/skapa-ny-skuggbokning" exact component={withFrontendLayout(ShadowBookingAdd)} />
                    <PrivateRoute path="/skuggbokning/lista-skuggbokningar" exact component={withFrontendLayout(ShadowBookingList)} />
                    <PrivateRoute path="/skuggbokning/redigera-skuggbokning/:id" exact component={withFrontendLayout(ShadowBookingEdit)} />
                    <PrivateRoute path="/skuggbokning/skapa-ny-skolbokning" exact component={withFrontendLayout(SchoolBookingAdd)} />
                    <PrivateRoute path="/skuggbokning/lista-skolbokningar" exact component={withFrontendLayout(SchoolBookingList)} />

                    {/* Reports */}
                    <PrivateRoute path="/rapporter/filmer" exact component={withFrontendLayout(ReportsFilm)} />
                    <PrivateRoute path="/rapporter/forsaljning" exact component={withFrontendLayout(ReportsSales)} />
                    <PrivateRoute path="/rapporter/forsaljning-per-produkt" exact component={withFrontendLayout(ReportsProductSales)} />
                    <PrivateRoute path="/rapporter/forsaljning-per-dag" exact component={withFrontendLayout(ReportsDailySales)} />
                    <PrivateRoute path="/rapporter/medlem" exact component={withFrontendLayout(ReportsMember)} />
                    <PrivateRoute path="/rapporter/salonger" exact component={withFrontendLayout(ReportsVenue)} />

                    <PrivateRoute exact path="/dev" component={withFrontendLayout(Dev)}/>
                    <PrivateRoute path="/dev/*" component={withFrontendLayout(Dev)}/>
                    <PrivateRoute component={withFrontendLayout(NotFound)}/>
                </Switch>
            </Router>
        </PersistGate>
    </Provider>,
    document.getElementById("application")
);

export {store};
