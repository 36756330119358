const initialState = {
    loading: false,
    filters: [],
    salesReports: [],
    productSalesReports: [],
    dailySalesReports: [],
    membershipStatusStats: {},
    membershipGenderStats: {},
    membershipAgeStats: 0,
    filmReports: [],
    venueReports: [],
    priceLevelsByEventCategory: []
}

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        // Pending default
        case 'REPORTS_PENDING' :
            return {
                ...state,
                loading: true
            }

        // Failed - default
        case 'REPORTS_FAILED' :
            return {
                ...state,
                loading: false
            }

        // Success - default
        case 'REPORTS_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
            }
            
        // Success - case specific
        case 'GET_SALES_REPORTS_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
                salesReports: payload
            }
        
        case 'GET_FILTERS_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
                filters: payload
            }
        
        case 'GET_PRODUCT_SALES_REPORTS_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
                productSalesReports: payload
            }
        
        // Memberships
        case 'GET_MEMBERSHIP_STATUS_STATS_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
                membershipStatusStats: payload
            }

        case 'GET_MEMBERSHIP_GENDER_STATS_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
                membershipGenderStats: payload
            }

        case 'GET_MEMBERSHIP_AGE_STATS_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
                membershipAgeStats: payload
            }

        case 'GET_FILM_REPORTS_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
                filmReports: payload
            }

        case 'GET_VENUE_REPORTS_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
                venueReports: payload
            }

        case 'GET_DAILY_SALES_REPORTS_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
                dailySalesReports: payload
            }

        case 'GET_PRICELEVELSBYEVENTCATEGORY_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
                priceLevelsByEventCategory: payload
            }
    
        default :
            return state;
    }
}