const initialState = {
    isDev: false,
    sidebarRendered: false
};

export default (state = initialState, action) => {
    const { type } = action;

    switch (type) {
        case 'SET_DEV_VIEW_ON':
            return {
                ...state,
                isDev: true
            };
            
        case 'SET_DEV_VIEW_OFF':
            return {
                ...state,
                isDev: false
            };

        default:
            return state;
    }
};