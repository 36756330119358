import {remapOptionsEventFilter} from "../../helpers/Helpers";

const initialState = {
    loading: false,
    singlePurchase: false,
    eventId:null,
    eventIdInvalid: null,
    membershipCardId:null,
    memberIdentifier:null,
    memberIdentifierInvalid: null,
    memberIdentifierInvalidMessage:"",
    venueId: null,
    venues: [],
    filmsId: null,
    films: [],
    viewId: null,
    viewings: [],
    priceTypes: [],
    member:null
}

export default (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {

        case "PURCHASE_RESET":
            return initialState;

        case "PURCHASE_FILTER_UPDATE":
            return {
                ...state,
                ...payload
            }

        //even filter
        case "PURCHASE_LOADING_EVENT_FILTER":
            return {
                ...state,
                loading: true,
            }

        case "PURCHASE_EVENT_FILTER_SUCCESS":
            let returnState = {
                ...state,
                loading: false,
            };

            returnState.viewings = remapOptionsEventFilter(payload.viewings);
            if (!returnState.viewId && returnState.viewings.length>0) {
                returnState.viewId = returnState.viewings[0].value;
            }

            returnState.films = remapOptionsEventFilter(payload.films);
            if (!returnState.filmId && returnState.films.length>0) {
                returnState.filmId = returnState.films[0].value;
            }

            if (state.venues.length === 0) {
                returnState.venues = remapOptionsEventFilter(payload.venues);
                if (!returnState.venueId && returnState.venues.length>0) {
                    returnState.venueId = returnState.venues[0].key;
                }
            } else {
                if (!returnState.venueId) {
                    returnState.venueId = returnState.venues[0].key;
                }
            }

            return returnState;

        case "PURCHASE_EVENT_FILTER_FAILURE":
            return {
                ...state,
                loading: false,
                venues: [],
                films: [],
                viewings: []
            }

        //checking event
        case "PURCHASE_LOADING_CHECK_EVENT_ID":
            return {
                ...state,
                loading: true,
                eventIdInvalid: null,
            }

        case "PURCHASE_CHECK_EVENT_ID_SUCCESS":
            return {
                ...state,
                loading: false,
                eventIdInvalid: false,
                viewId: payload.eventId,
                venueId: payload.venueId,
                filmId: payload.filmId,
                films: remapOptionsEventFilter(payload.filterData.films),
                venues: remapOptionsEventFilter(payload.filterData.venues),
                viewings: remapOptionsEventFilter(payload.filterData.viewings)
            }

        case "PURCHASE_CHECK_EVENT_ID_FAILURE":
            return {
                ...state,
                loading: false,
                eventIdInvalid: true,
            }

        //------------------- fetching the purchase
        case 'FETCH_PURCHASE_PENDING' :
            return {
                ...state,
                loading: true
            }

        case 'FETCH_PURCHASE_FAILED' :
            return {
                ...state,
                loading: false,
                singlePurchase: false,
            }

        case 'FETCH_PURCHASE_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
                singlePurchase: payload
            }



        //--------------------Price types -------------------------
        case "PURCHASE_LOADING_PRICE_TYPES":
            return {
                ...state,
                loading: true,
                priceTypes: []
            };
        case "PURCHASE_PRICE_TYPES_SUCCESS":
            let priceTypes = [];

            for(let x in payload){
                let item = payload[x];
                item.value = item.categoryPriceLevelId;
                item.priceLevelName = item.name;
                item.label = item.name + " ("+item.price+")";
                priceTypes.push(item);
            }

            return {
                ...state,
                loading: false,
                priceTypes: priceTypes
            };
        case "PURCHASE_PRICE_TYPES_FAILURE":
            return {
                ...state,
                loading: false,
                priceTypes: []
            }


        ///------------- purchase member validation -------------------------
        case "PURCHASE_LOADING_VALIDATE_MEMBER_ID":
            return {
                ...state,
                memberIdentifierInvalid: true,
                member: null
            };
        case "PURCHASE_VALIDATE_MEMBER_ID_SUCCESS":
            return {
                ...state,
                memberIdentifierInvalid: false,
                member: payload.membershipInfo
            };
        case "PURCHASE_VALIDATE_MEMBER_ID_FAILURE":
            return {
                ...state,
                memberIdentifierInvalid: true,
                member: null
            };


        ///------------- Make a purchase -------------------------
        case "PURCHASE_LOADING_MAKE_PURCHASE":
            return {
                ...state,
                loading:true,
            };
        case "PURCHASE_MAKE_PURCHASE_SUCCESS":
            return {
                ...state,
                filmId:null,
                viewId:null,
                venueId:null,
                loading:false,
            };
        case "PURCHASE_MAKE_PURCHASE_FAILURE":
            return {
                ...state,
                loading:false,
            };

        // ======== validate member email if its new or not
        case 'PURCHASE_LOADING_VALIDATE_MEMBER_EMAIL':
            return {
                ...state,
                loading:true,
                member:null,
                memberIdentifierInvalid:null,
            }
        case "PURCHASE_VALIDATE_MEMBER_EMAIL_SUCCESS":
            return {
                ...state,
                loading:false,
                member: typeof(payload)==="string" ? null : payload,
                memberIdentifierInvalid:false,
            }
        case "PURCHASE_VALIDATE_MEMBER_EMAIL_FAILURE":
            return {
                ...state,
                loading:false,
                member:null,
                memberIdentifierInvalid:true
            }

        //---------------- validate member card-------------------------
        case "PURCHASE_LOADING_VALIDATE_MEMBER_CARD":
            return {
                ...state,
                loading:true,
                memberIdentifierInvalid:null,
                memberIdentifierInvalidMessage:""
            }
            
        case "PURCHASE_VALIDATE_MEMBER_CARD_SUCCESS":
            return {
                ...state,
                loading:false,
                memberIdentifierInvalid:false,
                memberIdentifierInvalidMessage:""
            }
            
        case "PURCHASE_VALIDATE_MEMBER_CARD_FAILURE":
            return {
                ...state,
                loading:false,
                memberIdentifierInvalid:true,
                memberIdentifierInvalidMessage:payload.message
            }
        
        //---------------- validate member card-------------------------
        case "PURCHASE_LOADING_MAKE_MEBERSHIP_CARD_PURCHASE":
            return {
                ...state,
                loading:true,
                purchaseSuccess:null,
            }

        case "PURCHASE_MAKE_MEBERSHIP_CARD_PURCHASE_SUCCESS":
            return {
                ...state,
                loading:false,
                memberIdentifierInvalid:false,
                memberIdentifierInvalidMessage:"",
                membershipCardId:null,
                purchaseSuccess:true,
                member:null,
            }

        case "PURCHASE_MAKE_MEBERSHIP_CARD_PURCHASE_FAILURE":
            return {
                ...state,
                loading:false,
                purchaseSuccess:false,
            }
            
        case 'TRANSFER_PURCHASE_PENDING' :
            return {
                ...state,
                loading: true
            }
        
        case 'TRANSFER_PURCHASE_SUCCESSFUL' :
        case 'TRANSFER_PURCHASE_FAILED' :
            return {
                ...state,
                loading: false
            }

        default :
            return state;
    }
}