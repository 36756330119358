import { combineReducers } from 'redux';
import { combineForms } from 'react-redux-form';
import user from './user';
import members from './members';
import roles from './roles';
import membership from './membership';
import vouchers from './vouchers';
import booking from './booking';
import reports from './reports';
import purchases from './purchases';
import festivals from './festivals';
import films from './films';
import layout from './layout';
import venues from './venues';
import notifyReducer from 'react-redux-notify';

const reducers = combineReducers({
    user,
    roles,
    layout,
    members,
    membership,
    vouchers,
    booking,
    reports,
    purchases,
    festivals,
    films,
    venues,
    notifications: notifyReducer,
    forms: combineForms({
        searchPurchase: {},
        editUser: {},
        editUserRoles: {},
        editUserPass: {},
        addUser: {
            userInfo: {},
            userRoleIds: []
        },
        addMember: {},
        editMember: {},
        editMemberPass: {},
        editMemberRoles: {},
        addMembershipCard: {},
        addMembershipType: {},
        addMembership: {},
        addVoucher: {},
        addShadowBooking: {},
        addSchoolBooking: {},
        connectVoucherToCard: {},
        purchase: {},
        transferPurchase: {},
        generateNumbers: {},
        login: {},
        resetPassword: {},
        sendResetPasswordLink: {}
    }, 'forms')
});

export default reducers;

