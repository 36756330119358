const initialState = {
    loading: false,
    shadowBookingEvents: [],
    schoolBookingEvents: [],
    shadowBookings: [],
    schoolBookings: []
}

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        // Pending
        case 'CREATE_SHADOW_BOOKING_PENDING' :
        case 'CREATE_SCHOOL_BOOKING_PENDING' :
        case 'GET_SHADOW_BOOKING_EVENT_INFOS_PENDING' :
        case 'GET_SCHOOL_BOOKING_EVENT_INFOS_PENDING' :
        case 'GET_SHADOW_BOOKINGS_PENDING' :
        case 'GET_SCHOOL_BOOKINGS_PENDING' :
        case 'EDIT_SHADOW_BOOKING_PENDING' :
        case 'DELETE_SHADOW_BOOKING_PENDING' :
            return {
                ...state,
                loading: true,
            }

        // Failed - default
        case 'CREATE_SHADOW_BOOKING_FAILED' :
        case 'CREATE_SCHOOL_BOOKING_FAILED' :
        case 'GET_SCHOOL_BOOKINGS_FAILED' :
        case 'EDIT_SHADOW_BOOKING_FAILED' :
        case 'DELETE_SHADOW_BOOKING_FAILED' :
            return {
                ...state,
                loading: false,
            }
            
        // Failed - case specific
        case 'GET_SHADOW_BOOKINGS_FAILED' :
            return {
                ...state,
                loading: false,
                shadowBookings: []
            }
        
        case 'GET_SHADOW_BOOKING_EVENT_INFOS_FAILED' :
            return {
                ...state,
                loading: false,
                shadowBookingEvents: []
            }
        case 'GET_SCHOOL_BOOKING_EVENT_INFOS_FAILED' :
            return {
                ...state,
                loading: false,
                schoolBookingEvents: []
            }
                    
        // Success - default
        case 'CREATE_SHADOW_BOOKING_SUCCESSFUL' :
        case 'CREATE_SCHOOL_BOOKING_SUCCESSFUL' :
        case 'EDIT_SHADOW_BOOKING_SUCCESSFUL' :
        case 'DELETE_SHADOW_BOOKING_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
            }

        // Success - case specific
        case 'GET_SHADOW_BOOKING_EVENT_INFOS_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
                shadowBookingEvents: payload
            }


        case 'GET_SCHOOL_BOOKING_EVENT_INFOS_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
                schoolBookingEvents: payload
            }

        case 'GET_SHADOW_BOOKINGS_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
                shadowBookings: payload
            }

        case 'GET_SCHOOL_BOOKINGS_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
                schoolBookings: payload
            }
    
        default :
            return state;
    }
}