const initialState = {
    loading: false,
    types: [],
    numbers: [],
    createMembershipResponse: false,
    activateMembershipResponse:false,
    membershipCards: [],
    keyword: ''
}

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        // Pending
        case 'FETCH_TYPES_PENDING' :
        case 'FETCH_NUMBERS_PENDING' :
        case 'CREATE_MEMBERCARD_PENDING' :
        case 'FETCH_MEMBERCARDS_PENDING' :
        case 'INVALIDATE_MEMBERSHIP_PENDING' :
        case 'TRANSFER_CARD_PENDING' :
        case 'ACTIVATE_MEMBERCARD_PENDING' :
        case 'CREATE_MEMBERCARD_TYPE_PENDING' :
            return {
                ...state,
                loading: true,
                createMembershipResponse: false,
                activateMembershipResponse: false,
                numbers:[]
            }

        case 'DISCONNECT_CARD_PENDING' :
        case 'ADD_MEMBERSHIP_PENDING' :
            return {
                ...state,
                loading: true
            }

        // Failed
        case 'FETCH_TYPES_FAILED' :
        case 'FETCH_NUMBERS_FAILED' :
        case 'CREATE_MEMBERCARD_FAILED' :
        case 'FETCH_MEMBERCARDS_FAILED' :
        case 'INVALIDATE_MEMBERSHIP_FAILED' :
        case 'TRANSFER_CARD_FAILED' :
        case 'ACTIVATE_MEMBERCARD_FAILED' :
        case 'CREATE_MEMBERCARD_TYPE_FAILED' :
                return {
                    ...state,
                    loading: false,
                    createMembershipResponse: false,
                    activateMembershipResponse: false,
                }
        
        // Success - default
        case 'INVALIDATE_MEMBERSHIP_SUCCESSFUL' :
        case 'TRANSFER_CARD_SUCCESSFUL' :
        case 'CREATE_MEMBERCARD_TYPE_SUCCESSFUL' :
        case 'DISCONNECT_CARD_SUCCESSFUL' :
        case 'DISCONNECT_CARD_FAILED' :
        case 'ADD_MEMBERSHIP_SUCCESSFUL' :
        case 'ADD_MEMBERSHIP_FAILED' :
            return {
                ...state,
                loading: false
            }
                
        // Success - case specific
        case 'FETCH_TYPES_SUCCESSFUL' :
            let types = [];
            for(let x in payload){
                let item = payload[x];
                item.value = item.membershipTypeId;
                item.label = item.membershipTypeNameSv;
                types.push(item)
            }
            return {
                ...state,
                loading: false,
                types: types
            }

        case 'FETCH_NUMBERS_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
                numbers: payload
            }
        
        case 'CREATE_MEMBERCARD_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
                createMembershipResponse: payload
            }

        case 'ACTIVATE_MEMBERCARD_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
                activateMembershipResponse: payload
            }

        case 'FETCH_MEMBERCARDS_SUCCESSFUL' :
            return {
                ...state,
                loading: false,
                membershipCards: payload
            }

        // Keyword
        case 'CHANGE_MEMBERCARDS_KEYWORD' :
            return {
                ...state,
                keyword: payload
            }
    
        default :
            return state;
    }
}