const initialState = {
    //list of users, for search table
    items: [],
    itemsLoading: false
};

export default (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        // case "ROLES_RESET":
        //     return initialState;

        // // ROLES LIST ACTIONS
        // case "ROLES_LOADING_GET_LIST":
        //     return {
        //         ...state,
        //         itemsLoading: true
        //     };
        // case "ROLES_GET_LIST_SUCCESS":
        //     return {
        //         ...state,
        //         items: payload,
        //         itemsLoading: false
        //     };
        // case "ROLES_GET_LIST_FAILURE":
        //     if (isLocalHost()) {
        //         console.log("----------"+type+"----------");
        //         console.log(payload);
        //     }
        //     return {
        //         ...state,
        //         items: [],
        //         itemsLoading: false
        //     };

        case 'GET_ROLES_PENDING' :
            return {
                ...state,
                itemsLoading: true
            }
            
        case 'GET_ROLES_FAILED' :
            return {
                ...state,
                itemsLoading: false
            }

        case 'GET_ROLES_SUCCESSFUL' :
            return {
                ...state,
                itemsLoading: false,
                items: payload
            }

        default:
            return state;
    }
};