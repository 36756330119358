// import React from 'react';
import Config from '../config';
import axios from "axios/index";
import jwtDecode from 'jwt-decode';
import {createNotification} from "react-redux-notify";
import moment from 'moment';

export function isAdmin() {
    return isLogged();
}

export function isLogged() {
    //TODO is logged in
    var token = false;

    if (Config.getToken()) {
        token = jwtDecode(Config.getToken());

        let expirationDate = token.exp;
        let currentTimestamp = new Date().getTime() / 1000;

        // Not logged in if expiration date has expired
        if (expirationDate <= currentTimestamp) {
            Config.clearStorage();
            return false;
        }

        return true;
    }

    Config.clearStorage();
    return false;
}

export function makeid() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 5; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}

export function isLocalHost() {
    //10.0.2.2 - calls from virtual box
    return (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "10.0.2.2")
}

export function isBackend() {
    const renderer = document.getElementById('application').getAttribute('data-renderer');
    if (renderer === "backend") {
        return true;
    }
    return false;
}

export function isActiveMenu(currentPath, path, startsWith = false, pageTitle) {
    let isActive = false;

    if (startsWith) {
        isActive = currentPath.startsWith(startsWith);
    } else {
        isActive = currentPath === path;
    }

    if (isActive) {
        if (pageTitle && pageTitle !== document.title) {
            document.title = pageTitle;
        }
    }
    return isActive;
}

export function classNamesPrefixed(params) {
    let classes = "";


    // for(let x in params){

    // }

    return classes;
}

export function fixUrl(inUrl) {
    return inUrl;
}

export function getRequest(url, actionPrefix, action, params) {
    const loading = actionPrefix + '_LOADING_' + (action ? action : "");
    const success = actionPrefix + '_' + (action ? action : "") + "_SUCCESS";
    const failure = actionPrefix + '_' + (action ? action : "") + "_FAILURE";

    if (isLocalHost()) {
        console.log("passed api data", Config.getApiConfig());
        console.log(loading, success, failure);
    }

    return (dispatch) => {
        dispatch({
            type: loading
        });
        return axios.get(url, Config.getApiConfig())
            .then(response => {

                if (response.data) {

                    dispatch({
                        type: success,
                        payload: response.data
                    });

                    if (typeof (response.data) === "string") {
                        dispatch(createNotification(notificationMessage("success", response.data)));
                    }

                    if (response.data.message) {
                        dispatch(createNotification(notificationMessage("success", response.data.message)));
                    }

                } else {
                    dispatch({
                        type: failure,
                        payload: {
                            response: response,
                            action: action,
                            url: url,
                            actionPrefix: actionPrefix,
                            params: params
                        }
                    });
                }
            }).catch(error => {
                let message = "";
                if (error.response && error.response.data && typeof (error.response.data) === "string") {
                    message = error.response.data;
                }

                dispatch({
                    type: failure,
                    payload: {
                        message: message,
                        error: error,
                        action: action,
                        url: url,
                        actionPrefix: actionPrefix,
                        params: params
                    }
                });

                if (message) {
                    dispatch(createNotification(notificationMessage("error", message)));
                }
            });
    }
}

export function postRequest(url, actionPrefix, action, postData) {

    const loading = actionPrefix + '_LOADING_' + (action ? action : '');
    const success = actionPrefix + '_' + (action ? action : '') + '_SUCCESS';
    const failure = actionPrefix + '_' + (action ? action : '') + '_FAILURE';

    if (isLocalHost()) {
        console.log("posted to: ", url);
        console.log("passed postData ", postData);
        console.log(loading, success, failure);
    }


    return dispatch => {
        dispatch({
            type: loading
        });

        return axios.post(url, (postData ? postData : {}), Config.getApiConfig())
            .then((response) => {

                if (response.data || response.data === '') {
                    dispatch({
                        type: success,
                        payload: response.data
                    });

                    if (postData.messages && postData.messages.success) {
                        dispatch(createNotification(notificationMessage("success", postData.messages.success)));
                    }

                    return response;
                } else {
                    dispatch({
                        type: failure,
                        payload: {
                            response: response,
                            action: action,
                            url: url,
                            actionPrefix: actionPrefix,
                            postData: postData
                        }
                    });

                    return response;
                }
            }).catch((error) => {

                dispatch({
                    type: failure,
                    payload: {
                        error: error,
                        action: action,
                        url: url,
                        actionPrefix: actionPrefix,
                        postData: postData
                    }
                });

                if (postData.messages && postData.messages.error) {
                    dispatch(createNotification(notificationMessage("error", postData.messages.error)));
                } else {
                    if (error.response && error.response.data) {
                        showResponseNotifications(error.response.data, "error");
                    }
                }
            });
    }
}


export function showResponseNotifications(data, type = "info") {

    return dispatch => {
        if (typeof data === 'object') {
            let messagelist = data;
            if (data.messages) {
                messagelist = data.messages;
            }
            Object.keys(messagelist).forEach((err, index) => {
                setTimeout(() => {
                    if (typeof (messagelist[err]) === 'object') {
                        messagelist[err].forEach(message => {
                            dispatch(createNotification(notificationMessage(
                                'info',
                                message
                            )));
                        });
                    } else {
                        dispatch(createNotification(notificationMessage(
                            'info',
                            messagelist[err]
                        )));
                    }
                }, index * 250);
            })
        } else if (typeof data === 'string' || data instanceof String) {
            dispatch(createNotification(notificationMessage(
                'info',
                data
            )));
        }
    }
}

export function notificationMessage($type, $message, $duration) {
    let notification = {
        message: $message,
        duration: $duration ? $duration : 5000,
        canDismiss: true
    }

    switch ($type) {
        case 'success' :
            notification.type = 'SUCCESS';
            break;
        case 'error' :
            notification.type = 'ERROR';
            break;
        case 'warning' :
            notification.type = 'WARNING';
            break;
        case 'info' :
        default :
            notification.type = 'INFO';
            break;
    }

    return notification;
}

export const collapsibleSettings = {
    transitionTime: 150,
    easing: 'cubic-bezier(0.250, 0.460, 0.745, 1.405)'
}

export const confirmSettings = {
    title: 'Title',
    message: 'Message',
    buttons: [
        {
            label: 'Ja',
            // onClick: () => alert('Yes')
        },
        {
            label: 'Nej',
            // onClick: () => alert('No')
        }
    ]
}

export function translatePurchaseType(str) {
    let strLower = str.toLowerCase().trim();

    switch (strLower) {
        case 'cash' :
            return 'Kontant';

        case 'credit' :
            return 'Kredit';

        case 'none' :
            return 'Inget';

        default:
            return str;
    }
}

export function translatePurchaseStatus(str) {
    let strLower = str.toLowerCase().trim();

    switch (strLower) {
        case 'ordered' :
            return 'Beställd';

        case 'sent' :
            return 'Skickad';

        case 'collected' :
            return 'Insamlad';

        case 'canceled' :
            return 'Avbruten';

        case 'completed' :
            return 'Slutförd';

        case 'pending' :
            return 'Pågående';

        default:
            return str;
    }
}

export function remapOptionsEventFilter(options) {
    let remappedOptions = [];

    for (let x in options) {
        let item = options[x];
        item.label = item.value;
        item.value = item.key;
        remappedOptions.push(item);
    }

    return remappedOptions;
}

export function handleStandardError(dispatch, error, fallbackMessage) {
    if (error.response && error.response.data) {
        const {data} = error.response;

        if (typeof data === 'object') {
            Object.keys(data).forEach((err, index) => {
                setTimeout(() => {
                    data[err].forEach(message => {
                        dispatch(createNotification(notificationMessage(
                            'info',
                            message
                        )));
                    });
                }, index * 250);
            })
        } else if (typeof data === 'string' || data instanceof String) {
            dispatch(createNotification(notificationMessage(
                'info',
                data
            )));
        } else {
            dispatch(createNotification(notificationMessage(
                'error',
                fallbackMessage
            )));
        }
    } else {
        dispatch(createNotification(notificationMessage(
            'error',
            fallbackMessage
        )));
    }
}

export function toCommonDateFormat(date) {
    if (!date) {
        return null;
    }
    return moment(date).format('YYYY-MM-DD');
}
