export default {
    MEMBER:{
        STATUS:{
            ANY:null,
            ACTIVE:1,
            INACTIVE:0,
        }
    },
    MEMBERSHIP:{
        STATUS:{
            ACTIVE:"Active",
            INACTIVE:"Inactive",
        }
    },
    USER:{
        ROLE:{
            ANY:null
        }
    },
    LIST:{
        ITEMS_PER_PAGE:30
    },
    REPORTTYPE:{
        SALES: 0,
        FILM: 1,
        VENUE: 2,
        PRODUCTSALES: 3,
        DAILYSALES: 4
    },
    PRODUCTSALESREPORTFILTERTYPE: {
        AllProducts: 0,
        Memberships: 1,
        Tickets: 2,
        Vouchers: 3,
        EventCategories: 4
    }
};